import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const CollegeWritingServicePage = () => (
  <Layout
    pageWrapperClassName="buy-personal-statement-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Buy Personal Statement - Order Personal Statement"
      meta={[
        {
          name: 'description',
          content: 'Buy Personal Statement at TakeAwayEssay.com 📝 is the perfect solution to buy personal statement online. Our experienced writers provide 24/7 customer support and offer top-notch quality and guarantees, as evidenced by thousands of positive reviews.',
        },

        {
          property: 'og:title',
          content: 'Buy Personal Statement Online at Affordable Prices | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'Buy personal statement online with top quality and at affordable prices. 24/7 service availability. 100% uniqueness, compliance with your requirements, free revisions. Order now and get prime quality paper within the shortest deadline. Placing an order takes some minutes.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/buy-personal-statement.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Your personal statement deserves
        <br className="first-screen-section__title__br" />
        {' '}
        every piece of help you can find online
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        Get personalized assistance and
        <br className="first-screen-section__heading__br" />
        {' '}
        compose a stunning essay
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Order personal statement: buy personal statement
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            Admission to a college or university is a difficult process. Many colleges and
            universities ask each applicant to write a personal statement as part of the admission
            process. In this document, the applicant must write about his or her motivation to study
            a certain subject. Additionally, applicants must describe their skills to accomplish
            their goals.
          </p>
          <p>
            Many students feel frustrated when they try to write this important paper. If you are in
            such a situation, you have to know there is a way out.{' '}
            <strong>
              You don’t have to ignore help when writing your academic papers. You can look for
              external assistance and hire someone to lend a hand.{' '}
            </strong>
            This applies to the writing of a personal statement too. In other words, you can buy
            personal statement which will become your source of inspiration.
          </p>
          <p>
            But, where can a student buy personal statement? Simple, on the Internet. Several online
            companies offer professional writing services for students. You tell them what you need,
            and they write a custom paper according to your specifications. It is easy, isn’t it? Of
            course, you have to pay for the service. And they can write a personal statement of
            supreme quality for you.
          </p>
          <h2 className="h3">Buy personal statement from a reputable writing service provider</h2>
          <p>
            You must know that not all the writing companies out there are reliable. If you decide
            to buy personal statement, you must be sure you spend your money wisely. This is why you
            should use the services of reputable companies only. We recommend{' '}
            <a href="https://takeawayessay.com/">https://takeawayessay.com/</a>. This company is the
            best choice to buy personal statement online. Read on and learn why.
          </p>
          <p>
            You should buy personal statement online from a company that has experience with
            academic writing in general. And the aforementioned company has vast experience writing
            every type of paper and essay for students. For example, they can write:
          </p>
          <ul className="dot-list">
            <li>Assignments for any course</li>
            <li>Essays in a large variety of topics</li>
            <li>Research papers for the end of the term (semester or quarter)</li>
            <li>An entire dissertation for any academic degree (bachelor’s, master’s, PhD)</li>
          </ul>
          <p>
            What’s more, we deliver top-quality papers at very affordable prices. Moreover,
            according to some clients, our service is second to none. Our professional writers can
            work very fast to complete on time even the most urgent assignment. Hence, don’t
            hesitate to purchase an essay or a personal statement from us. Place an order, it is
            easy. We’ll tell you how to do it.
          </p>
          <h2 className="h3">It is easy to purchase personal statement online</h2>
          <p>
            To purchase personal statement online, you need to create an account on{' '}
            <a href="https://takeawayessay.com/">https://takeawayessay.com/</a>. Then, you have to
            fill in an order form with all the data about the paper you need. Short after submitting
            your order, a professional writer will start working on your paper. You will know the
            total price of your order even before you submit it.
          </p>
          <p>
            A personal statement is rather cheap. Hence, you can buy it without any problems. The
            helper who writes your personal statement will complete it within the established
            deadline. Make no mistake though: your writer will never compromise the quality.
          </p>
          <p>
            Why the services of our company are highly rated? The answer is because we have multiple
            advantages. Besides sale prices and top quality, the company keeps all your data
            confidential. As a consequence, your relationship with our company remains a private
            matter.
          </p>
          <p>
            Moreover, all the papers we deliver are free of plagiarism. The team of professional
            writers is supervised by several editors that check the uniqueness of each paper. A
            strict non plagiarism policy is enforced.
          </p>
          <p>
            Hence, order personal statement online with our company and get affordable professional
            help. When you are admitted and start studying at university, you can continue using the
            services of{' '}
            <a href="https://takeawayessay.com/">https://takeawayessay.com/</a>. You count on this
            company whenever you want to ask someone “help me with my homework, please.”
          </p>
          <h2 className="h3">Is it legal to order personal statement online?</h2>
          <p>
            Of course, it is! Purchasing personal statement is legit, don’t worry. You don’t break
            the law by placing an order with our company. There is nothing wrong with looking for
            help when you need it. Hence, don’t hesitate and start using our writing services.
          </p>
          <p>
            The personal statement you get from a professional writer will help you with your
            admission to the university of your choice. Think that the money you pay is an
            investment for your future. Don’t wait, order now!
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Buy paper">
      Get professional help from
      <br />
      {' '}
      the best writers.
    </CTA>

  </Layout>
);

export default CollegeWritingServicePage;
